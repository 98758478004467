import * as React from "react";
import { RegistrationModalColors } from "components/EventInvitation/Modals/registration.constants";
import { getRegistrationModalData, RegistrationData } from "components/EventInvitation/Modals/registration";
import { CircleIcon } from "components/common/CircleIcon/CircleIcon";
import { Modal, SGBSColor } from "@sgbs-ui/core";
import { EventSlotType } from "api/events/event.types";
import { InvitationActionType } from "components/EventInvitation/useEventInvitations";

type Props = {
  action: InvitationActionType;
  slotType?: EventSlotType;
  onClose: () => void;
};

export const RegistrationConfirmationModal: React.VFC<Props> = ({ action, onClose, slotType }) => {
  const { status, icon, message, title } = getRegistrationModalData(action);

  const header = () => renderHeader({ status, icon, message, title });

  return (
    <Modal
      show={true}
      size="md"
      type={RegistrationModalColors[action] as SGBSColor}
      footerClassName="modal-footer d-flex justify-content-center pb-5"
      renderHeaderActions={header}
      onClose={onClose}
      cancelLabel="Close"
    />
  );
};

const renderHeader = ({ status, icon, message, title }: RegistrationData) => (
  <div className="d-flex align-items-center flex-column w-100">
    <CircleIcon status={status}>
      <i className={`icon icon-md text-${status} line-height-1`}>{icon}</i>
    </CircleIcon>
    <h3 className={`modal-title row text-${status} mb-2`}>{title}</h3>
    <p className="fs-4 text-secondary m-0">{message}</p>
  </div>
);
