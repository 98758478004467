import { useModal } from "@ic-anywhere/ic-components";
import { isStringEmpty, logMessage, LogTypes } from "@ic-anywhere/ic-utils";
import { EventSlotType, showCommentForSlotTypes } from "api/events/event.types";
import { AddressType } from "common/event/event.types";
import VirtualLocation from "components/EventInvitation/EditableSlotItem/VirtualLocation";
import { VirtualLocationInfoModal } from "components/EventInvitation/Modals/VirtualLocationInfoModal";
import { Slot } from "containers/event/event.types";
import { isEmpty } from "lodash";
import * as React from "react";
import { isAddressComplete } from "utils/address/addressUtil";
import { features } from "utils/logger/features";
import { AddressPoint } from "../../AddressPoint/AddressPoint";
import { AttendeeItem } from "../CardItem/AttendeeItem";
import { renderInfoOfSlotInProgram } from "./SlotsDetailBody.utils";
import * as teamsIcon from "assets/images/teams_icon.png";

type Props = {
  slot: Slot;
  position: string;
};
export const SlotsDetailBody: React.VFC<Props> = ({ slot, position }) => {
  const { invitees, attendees, address, type, comment, virtualLocation, eventVenue, addressType } = slot;
  const displayEventVenue = type === EventSlotType.GroupLunch && addressType === AddressType.BusinessAddress;
  const addressToDisplay = displayEventVenue ? { ...eventVenue.address, venueName: eventVenue.name } : address;
  const [showModal, openModal, closeModal] = useModal();

  const logBanksSectionAction = (index: string) => {
    const isExpanded = JSON.parse(document.querySelector(`#attendees${index}`).getAttribute("aria-expanded"));
    const featureName = `roadshowPageAttendeesList${isExpanded ? "Extend" : "Collapse"}`;
    logMessage({
      ...features[featureName],
      type: LogTypes.functional,
      feature: "event",
      name: "Roadshow page attendees list",
    });
  };

  return (
    <>
      {renderInfoOfSlotInProgram(slot, { showCapacity: true, className: "fw-bolder" })}
      {!isEmpty(invitees) && (
        <li className="mb-1">
          {attendees.length > 5 ? (
            <div id={`accordion${position}`} className="card-header p-0">
              <a
                id={`attendees${position}`}
                className="btn  justify-content-start p-0 collapsed"
                data-toggle="collapse"
                data-target={`#collapse${position}`}
                aria-expanded="true"
                aria-controls={`collapse${position}`}
                onClick={() => logBanksSectionAction(position)}
              >
                Attendees ({attendees.length})
                <i className="icon icon-sg-event icon-sm ms-2 text-primary">keyboard_arrow_right</i>
              </a>
              <div
                id={`collapse${position}`}
                className="collapse show"
                aria-labelledby={`collapse${position}`}
                data-parent={`#accordion${position}`}
              >
                <div className="card-body p-0">
                  <AttendeeItem invitees={invitees} />
                </div>
              </div>
            </div>
          ) : (
            <AttendeeItem invitees={invitees} />
          )}
        </li>
      )}
      {isAddressComplete(addressToDisplay) && (
        <li className="mb-1">
          <AddressPoint address={addressToDisplay} />
        </li>
      )}
      {virtualLocation && virtualLocation.url && (
        <>
          <li className="d-lg-block d-none align-items-center mb-1">
            <VirtualLocation {...virtualLocation} className="ms-1 text-secondary" />
          </li>
          <li className="d-lg-none mb-1">
            <img src={teamsIcon} width="24px" height="24px" alt="teams" onClick={() => openModal()} />
          </li>
        </>
      )}
      {!isStringEmpty(comment) && showCommentForSlotTypes(type) && <li className="mb-1">{comment}</li>}
      {showModal && <VirtualLocationInfoModal virtualLocation={virtualLocation} onClose={closeModal} />}
    </>
  );
};
