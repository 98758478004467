import * as React from "react";
import { map, size, sortBy } from "lodash";
import { EventContact } from "api/events/event.types";

type AttendeeItemProps = {
  invitees: EventContact[];
};

export const AttendeeItem: React.FC<AttendeeItemProps> = ({ invitees }) => (
  <div>
    <ul className="list-unstyled fw-bolder">{size(invitees) > 0 ? formattedInvitees(invitees) : ""}</ul>
  </div>
);

const formattedInvitees = (invitees: EventContact[]) =>
  map(sortBy(invitees || [], ["accountName", "friendlyName"]), (invitee, index) => (
    <li key={index}>
      {!!invitee && !!invitee.accountName && !!invitee.jobTitle ? (
        <>
          {invitee.accountName}
          <span className="text-secondary fw-normal">
            - {invitee.friendlyName} ({invitee.jobTitle})
          </span>
        </>
      ) : (
        <span className="text-secondary fw-normal"> - {invitee.friendlyName}</span>
      )}
    </li>
  ));
