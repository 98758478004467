import * as React from "react";
import { EventDetail } from "containers/event/event.types";
import { CollapsibleContactsCard } from "components/Event/Corporate/Contact/CardItem/CollapsibleContactsCard";
import { ContactsGroups } from "../Contact/ContactsGroups";
import { groupInvitationByStatus } from "components/Event/Corporate/invitationStatus.mapper";
import { useGetInvitationsQuery } from "queries/useGetInvitationsQuery";
import { flatten } from "lodash";
import { Loader } from "@ic-anywhere/ic-components";
import { features } from "utils/logger/features";
import { useTrackOnDisplayPage } from "../../../common/event.tracking";

const accordionId = "accordionInvitationStatus";

export const EventCorporateInvitationStatusTab: React.VFC<{ eventDetail: EventDetail }> = ({ eventDetail }) => {
  const { data, isFetching, hasNextPage } = useGetInvitationsQuery(eventDetail.event.id);
  const inviteeByStatus = groupInvitationByStatus(flatten(data));
  useTrackOnDisplayPage(features.eventDetailsInvitationStatusDisplay);
  if (isFetching || hasNextPage) {
    return (
      <div className="mb-4 mt-5">
        <Loader isVisible={true} />
      </div>
    );
  }

  return (
    <>
      <h3 className="mb-4 mt-4 mt-md-5 ms-2">Invitation Status</h3>
      <div className="row w-100 mx-0">
        <div id={accordionId} className="col-md-10 col-lg-8">
          {inviteeByStatus.map((inviteeStatus, index) => (
            <CollapsibleContactsCard
              key={index}
              id={index}
              accordionId={accordionId}
              title={
                <span>
                  {inviteeStatus.name}
                  <span className="badge badge-primary badge-md rounded-pill ms-1">
                    {inviteeStatus.invitees.length}
                  </span>
                </span>
              }
            >
              <ContactsGroups contacts={inviteeStatus.invitees} contactInfoModal={false} />
            </CollapsibleContactsCard>
          ))}
        </div>
      </div>
    </>
  );
};
