import * as React from "react";
import { FieldPhoneOrMail } from "../Tabs/CardItem/FieldPhoneOrMail";
import { Button } from "@sgbs-ui/core";

interface Props {
  businessPhone?: string;
  mobilePhone?: string;
  mainEmail?: string;
  className?: string;
  displayIcon?: boolean;
}

export const ContactInfoWithLink: React.FC<Props> = ({
  businessPhone,
  mobilePhone,
  mainEmail,
  className = "",
  displayIcon = false,
}) => {
  return (
    <ul className={`list-unstyled m-0 ${className}`}>
      {businessPhone && (
        <li>
          <a href={`tel:${businessPhone}`}>
            {displayIcon ? (
              <Button icon="phone" btnType="discreet" color="secondary" size="sm" className="ms-2 rounded-circle" />
            ) : (
              <FieldPhoneOrMail field={businessPhone} type={"Tel"} />
            )}
          </a>
        </li>
      )}
      {mobilePhone && (
        <li>
          <a href={`tel:${mobilePhone}`}>
            {displayIcon ? (
              <Button
                icon="smartphone"
                btnType="discreet"
                color="secondary"
                size="md"
                className="rounded-circle ms-2"
              />
            ) : (
              <FieldPhoneOrMail field={mobilePhone} type={"Mob"} />
            )}
          </a>
        </li>
      )}
      {mainEmail && (
        <li>
          <a href={`mailto:${mainEmail}`}>
            {displayIcon ? (
              <Button icon="email" btnType="discreet" color="secondary" size="md" className="rounded-circle ms-2" />
            ) : (
              <FieldPhoneOrMail field={mainEmail} type={"Email"} />
            )}
          </a>
        </li>
      )}
    </ul>
  );
};
