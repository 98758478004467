import * as React from "react";
import { EventExtractProgramProps } from "components/Event/Corporate/EventExtractProgram/EventExtractProgram";
import { isEmpty, isEqual, map, size, union, uniqWith } from "lodash";
import { ContactNameAndPhoneNumber } from "components/Event/Corporate/Tabs/CardItem/ContactNameAndPhoneNumber";
import { pluralize } from "@ic-anywhere/ic-utils";
import { issuersGroupByAccountName } from "components/Event/Corporate/Tabs/EventCorporateRoadshowTab";
import { EventContact } from "api/events/event.types";

export default function Contacts({ eventDetail: { useFulContacts, issuers } }: EventExtractProgramProps): JSX.Element {
  const issuerContacts = issuersGroupByAccountName(issuers);
  const drivers = useFulContacts?.yourDriver ?? [];
  const eventManagers = useFulContacts?.eventManagerContacts ?? [];
  const businessContacts = useFulContacts?.businessContacts ?? [];
  const internalSpeakers = useFulContacts?.internalSpeakers ?? [];
  const businessContactsList = uniqWith(union(businessContacts || [], internalSpeakers || []), isEqual);

  return (
    <div className="mt-5 mb-5 break-inside-avoid">
      <div className="d-flex align-items-start justify-content-between">
        <div className="w-50 d-flex flex-column">
          <ul className="list-unstyled">
            {issuerContacts.map((issuer, index) => (
              <li key={index} className="mb-4">
                <h4 className="mb-2">{issuer.accountName}</h4>
                {map(issuer.issuers as EventContact[], (issuer) => (
                  <ul key={issuer.id} className="list-unstyled">
                    <li>{`${issuer.friendlyName} (${issuer.jobTitle})`}</li>
                  </ul>
                ))}
              </li>
            ))}
          </ul>
          {!isEmpty(drivers) && (
            <ContactNameAndPhoneNumber title={`Your ${pluralize("driver", size(drivers))}`} contacts={drivers} />
          )}
        </div>
        <div className="d-flex ms-2">
          <div className="me-5">
            {!isEmpty(eventManagers) && (
              <ContactNameAndPhoneNumber
                title={`Event ${pluralize("manager", size(eventManagers))}`}
                contacts={eventManagers}
              />
            )}
          </div>
          <div>
            {!isEmpty(businessContactsList) && (
              <ContactNameAndPhoneNumber
                title={`Business ${pluralize("contact", size(businessContactsList))}`}
                contacts={businessContactsList}
              />
            )}
            {!isEmpty(internalSpeakers) && (
              <ContactNameAndPhoneNumber
                title={`Internal ${pluralize("speaker", size(internalSpeakers))}`}
                contacts={internalSpeakers}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
