import * as React from "react";
import { VirtualLocation as VirtualLocationProps } from "common/event/event.types";
interface Props {
  className?: string;
}
const VirtualLocation: React.FC<Props & VirtualLocationProps> = ({ url, phone, phoneCode, className }) => (
  <>
    <div className="line-height-lg align-items-center d-print-none">
      {url && (
        <a href={url} type="button" className="btn btn-sm btn-primary" target="_blank" rel="noopener noreferrer">
          <span>Join session</span>
          <i className="icon icon-sm ms-1 d-lg-none pe-2 1">cast</i>
        </a>
      )}
      {phone && (
        <a href={`tel:${phone}`}>
          <span className={className}>Tel: {phone}</span>
        </a>
      )}
      {phoneCode && <span className={className}> Conference ID: {phoneCode}</span>}
    </div>
    <div className="d-none d-print-block">
      {url && (
        <a href={url} className="slot-location d-block" color="info" target="_blank" rel="noopener noreferrer">
          Click here to join session
        </a>
      )}
      {phone && <span className={`${className} d-block`}>Tel: {phone}</span>}
      {phoneCode && <span className={`${className} d-block`}> Conference ID: {phoneCode}</span>}
    </div>
  </>
);

export default VirtualLocation;
