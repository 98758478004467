import * as React from "react";
import { logMessage } from "utils/logger/logger";
import { features } from "utils/logger/features";
import { LogTypes } from "utils/logger/logger.typings";
import { partial } from "lodash";
import Responsive from "components/common/Responsive/Responsive";
import { EventResourceCategory } from "api/events/event.types";
import { useExportPdf } from "queries/useExportPdf";

type Props = {
  eventId: string;
  resourceId: string;
  category: EventResourceCategory;
};

export const ExportPdfButton: React.FunctionComponent<Props> = ({ eventId, resourceId, category }) => {
  const track = partial(logExportAction, category);

  const { refetch: fetchExcelFile } = useExportPdf(eventId, resourceId);

  const downloadFile = async () => {
    track();
    await fetchExcelFile();
  };

  return (
    <div className="float-right mt-5">
      <Responsive
        children={
          <div className="btn-group" role="group" aria-label="export">
            <span className="btn btn-outline-primary" onClick={downloadFile}>
              <i className="icon icon-sm material-icons me-2">save_alt</i>
              Export
            </span>
          </div>
        }
        mobileChildren={
          <div className="btn-group" role="group" aria-label="export">
            <span className="btn btn-sm btn-outline-primary" onClick={downloadFile}>
              <i className="icon icon-sm">save_alt</i>
            </span>
          </div>
        }
      />
    </div>
  );
};

const logExportAction = (category: EventResourceCategory) => {
  logMessage({
    ...(category === "Feedback" ? features.feedbackPageResourceExport : features.investorProfilesPageResourceExport),
    type: LogTypes.functional,
    feature: "event",
    name: `Export ${category === "Feedback" ? "feedback" : "investor profile"} resource`,
  });
};
