import * as React from "react";
import { isEmpty } from "lodash";
import { Address } from "common/event/event.types";
import * as styles from "components/Event/Corporate/AddressPoint/AddressPoint.scss";
import { logMessage } from "utils/logger/logger";
import { features } from "utils/logger/features";
import { LogTypes } from "utils/logger/logger.typings";
import { getAddressFormatted } from "utils/address/addressUtil";
import { useGetGoogleUrls } from "@ic-anywhere/ic-components";

interface Props {
  address?: Address;
  addressText?: string;
  googleMap?: boolean;
}

export const AddressPoint: React.VFC<Props> = ({ address, addressText, googleMap = true }) => {
  const getGoogleUrls = useGetGoogleUrls();
  const isAddressOrAddressText = !isEmpty(address) || !isEmpty(addressText);
  if (!isAddressOrAddressText) {
    return null;
  }

  const { navigateToUrl } = getGoogleUrls(address);

  const className = googleMap ? `${styles["cursor-pointer"]} text-secondary` : `text-secondary`;
  const logGoogleMapOpenAction = () => {
    logMessage({
      ...features.roadshowPageGoogleMapOpen,
      type: LogTypes.functional,
      feature: "event",
      name: "Roadshow page open Google map",
    });
  };
  return (
    <>
      {!googleMap ? (
        <div>
          <span className={className}>
            <i className="icon icon-sg-event icon-xs">location_on</i>
            {!isEmpty(address) ? getAddressFormatted(address) : addressText}
          </span>
        </div>
      ) : (
        <div className={`${isEmpty(address) ? styles["ic-map"] : ""}`}>
          <a
            className={`${className} slot-location`}
            href={navigateToUrl}
            target="_blank"
            onClick={logGoogleMapOpenAction}
            rel="noopener noreferrer"
          >
            <i className="icon icon-sg-event icon-xs">location_on</i>
            {!isEmpty(address) ? getAddressFormatted(address) : addressText}
          </a>
        </div>
      )}
    </>
  );
};
