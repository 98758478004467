import * as React from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

interface Props {
  to: string;
  className?: string;
  title: React.ReactElement | string;
  openInNewTab?: boolean;
  onClick?: () => void;
  matchRoutes?: string[];
}

export const NavItem: React.FunctionComponent<Props> = ({
  to,
  title,
  openInNewTab = false,
  matchRoutes = [],
  onClick,
}) => {
  const handleOnClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  const isActive = useRouteMatch({ path: [to, ...matchRoutes], exact: true });

  return (
    <li className="nav-item w-auto">
      {!openInNewTab ? (
        <NavLink
          to={to}
          onClick={handleOnClick}
          className="nav-link   text-truncate"
          exact={true}
          strict={true}
          isActive={() => !!isActive}
        >
          {title}
        </NavLink>
      ) : (
        <a onClick={handleOnClick} href={to} target="_blank" rel="noreferrer" className="nav-link text-truncate">
          {title}
        </a>
      )}
    </li>
  );
};
