import * as React from "react";
import { ROUTES } from "common/routes";
import { get } from "lodash";
import { BackButton } from "@ic-anywhere/ic-components";
import ButtonLink from "components/common/ButtonLink";

export interface ErrorPageProps {
  shown: boolean;
  header?: string;
  message?: string;
  goBack?: boolean;
  match?: any;
}

const messageByErrorStatus: { [errorStatus: number]: string } = {
  404: "Cannot find the requested resource.",
  403: "You are not authorised to display the requested resource.",
  500: "Internal server error.",
};

export const ErrorPage: React.VFC<ErrorPageProps> = ({ shown = true, header, message, goBack, match }) => {
  const errorStatus = get(match, "params.errorStatus");
  const errorMessage = (errorStatus ? messageByErrorStatus[errorStatus] : message) || messageByErrorStatus[404];
  return shown ? (
    <div className="w-50 mx-auto mt-5">
      <h1 className="display-3">{header || errorStatus || "404"}</h1>
      <p className="lead">{errorMessage}</p>
      <div className="ms-1">
        {goBack ? <BackButton /> : <ButtonLink to={ROUTES.HOME} color="secondary" label="Back to Home" />}
      </div>
    </div>
  ) : null;
};
