import * as React from "react";
import { BackButton } from "@ic-anywhere/ic-components";

export interface Props {
  shown: boolean;
  header?: string;
  message?: string;
  goBack?: boolean;
  match?: any;
}

const Page500: React.FunctionComponent<Props> = ({ shown = true }) => {
  const openHelpCenter = () => {
    const selector = "sgwt-help-center";
    const elt = document.querySelector(selector);
    if (elt) {
      (elt as any).form();
    }
  };
  return shown ? (
    <body className="d-flex flex-column mt-5">
      <section className="section-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-2 bg-white">
                <span
                  style={{ fontSize: "96px", overflow: "hidden" }}
                  className="mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt fw-normal"
                >
                  500
                </span>
                <span className="mt-n2 d-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt fw-normal">
                  500
                </span>
              </div>
              <div className="col-md-5 bg-white d-flex flex-column justify-content-between">
                <div>
                  <h1 className="display-2 fw-normal line-height-1 mb-3">Something's wrong.</h1>

                  <p className="d-none d-xxl-block display-3 fw-normal text-secondary w-75 line-height-1">
                    We're unable to complete your request at the moment.
                  </p>
                  <p className="d-xxl-none display-4 fw-normal text-secondary w-75 line-height-1">
                    We're unable to complete your request at the moment.
                  </p>
                </div>
                <div className="mb-5 d-flex">
                  <BackButton className="btn btn-xl btn-primary" label="Go back" />
                  <button className="btn btn-xl btn-flat-secondary ms-1" onClick={openHelpCenter}>
                    Contact us
                  </button>
                </div>
              </div>
              <div className="col-4 px-0 position-relative">
                <div
                  style={{
                    paddingTop: "100%",
                    background:
                      "url(https://sgithub.fr.world.socgen/pages/sgbootstrap/sg-bootstrap-v4/master/demo/templates/error-pages/500.svg) center no-repeat / cover",
                    backgroundSize: "130%",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-3 flex-grow-1 d-flex">
        <div className="container-fluid">
          <div className="container h-100">
            <div className="row h-100">
              <div className="offset-2 col-5 pt-5 text-muted" style={{ backgroundColor: "#fafafa" }} />
            </div>
          </div>
        </div>
      </section>

      <script
        crossOrigin="anonymous"
        integrity="sha384-/IFzzQmt1S744I+IQO4Mc1uphkxbXt1tEwjQ/qSw2p8pXWck09sLvqHmKDYYwReJ"
        src="https://code.jquery.com/jquery-3.5.0.slim.min.js"
      />
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.0/umd/popper.min.js"
        integrity="sha384-cs/chFZiN24E4KMATLdqdvsezGxaGsi4hLGOzlXwp5UZB1LY//20VyM2taTB4QvJ"
        crossOrigin="anonymous"
      />
      <script
        src="https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/js/bootstrap.min.js"
        integrity="sha384-uefMccjFJAIv6A+rW+L4AHf99KvxDjWSu1z9VI8SKNVmz4sk7buKt/6v9KI65qnm"
        crossOrigin="anonymous"
      />
    </body>
  ) : null;
};
export default Page500;
