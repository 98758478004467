import * as React from "react";
import { filter, isEmpty } from "lodash";
import { AddressPoint } from "components/Event/Corporate/AddressPoint/AddressPoint";
import { Slot } from "containers/event/event.types";
import { BookingDetail, EventDateRange, showCommentForSlotTypes } from "api/events/event.types";
import {
  BookingMessageStatus,
  BookingMessageStatusColors,
} from "components/EventInvitation/Modals/registration.constants";
import { BookingStatus } from "common/event/event.types";
import { GroupedSlot, GroupedSlots, groupSlots } from "components/EventInvitation/useEventInvitations.rules";
import { mapToIsoDateFormat } from "utils/dateUtils";
import { isAddressComplete } from "utils/address/addressUtil";
import { TimelineSlotMultiDateItem } from "components/common/EventTimelineItems/TimelineSlotMultiDateItem";
import { isStringEmpty, jsDateFromIso } from "@ic-anywhere/ic-utils";
import VirtualLocation from "./VirtualLocation";
import { renderInfoOfSlotInProgram } from "components/Event/Corporate/Tabs/SlotsDetail/SlotsDetailBody.utils";
import { TimeLine, useModal } from "@ic-anywhere/ic-components";
import * as teamsIcon from "assets/images/teams_icon.png";
import { VirtualLocationInfoModal } from "../Modals/VirtualLocationInfoModal";

interface ReadBookingDetailsProps {
  bookingDetails: BookingDetail[];
  eventDateRange: EventDateRange;
  showSlotStatus?: boolean;
}

export const ReadBookingDetailsItem: React.FunctionComponent<ReadBookingDetailsProps> = ({
  bookingDetails,
  eventDateRange,
  showSlotStatus = false,
}) => {
  if (isEmpty(bookingDetails)) {
    return null;
  }

  const groupedSlots = groupSlots(bookingDetails, (_) => ({}));
  const isMultiSlotEvent = bookingDetails.length > 1;
  return isMultiSlotEvent
    ? renderMultiSlotEvent(groupedSlots, showSlotStatus)
    : renderOneSlotEvent(groupedSlots[0], showSlotStatus, eventDateRange.startDate);
};

const renderMultiSlotEvent = (groupedSlots: GroupedSlots<GroupedSlot>[], showSlotStatus: boolean): JSX.Element => (
  <TimeLine className={"align-items-start px-lg-2"}>
    {groupedSlots.map(({ startDateIso, slotsOfDay }, dayIdx) => {
      const attendingSlots = filter(slotsOfDay, (s: GroupedSlot) => s.bookingStatus === BookingStatus.Booked);
      if (isEmpty(attendingSlots)) {
        return null;
      }
      return (
        <TimelineSlotMultiDateItem key={dayIdx} date={jsDateFromIso(startDateIso)} active={true}>
          <ul className="list-unstyled">
            {attendingSlots.map((s) => (
              <SlotInfo key={s.slot.id} slot={s.slot} bookingStatus={s.bookingStatus} showSlotStatus={showSlotStatus} />
            ))}
          </ul>
        </TimelineSlotMultiDateItem>
      );
    })}
    <li />
  </TimeLine>
);

const renderOneSlotEvent = (
  { slotsOfDay, startDateIso, startDateYear, startDateDayMonth },
  showSlotStatus: boolean,
  eventStartDate: Date
) => {
  const slotDateDifferentFromEventDate = startDateIso !== mapToIsoDateFormat(eventStartDate);
  return (
    <TimeLine className={"align-items-start"}>
      <li className="w-100 col-12">
        {slotDateDifferentFromEventDate && (
          <>
            <span className="timeline-date" style={{ marginTop: "-0.5rem" }}>
              {startDateYear}
            </span>
            <span className="timeline-date display-4">{startDateDayMonth}</span>
          </>
        )}
        <ul className={"list-unstyled"}>
          {slotsOfDay.map(({ slot, bookingStatus }, index: number) => (
            <SlotInfo key={slot.id} slot={slot} bookingStatus={bookingStatus} showSlotStatus={showSlotStatus} />
          ))}
        </ul>
      </li>
    </TimeLine>
  );
};

type SlotInfoProps = { slot: Slot; bookingStatus: BookingStatus; showSlotStatus: boolean };

const SlotInfo: React.VFC<SlotInfoProps> = ({ slot, bookingStatus, showSlotStatus }) => {
  const [showModal, openModal, closeModal] = useModal();

  return (
    <>
      {renderInfoOfSlotInProgram(slot, {
        className: "fw-bolder",
        badge: showSlotStatus && {
          color: BookingMessageStatusColors[bookingStatus],
          text: BookingMessageStatus[bookingStatus],
        },
      })}
      {!isStringEmpty(slot.comment) && showCommentForSlotTypes(slot.type) && <li>{slot.comment}</li>}
      {isAddressComplete(slot.address) && (
        <li className="mb-1">
          <AddressPoint address={slot.address} />
        </li>
      )}
      {!!slot.virtualLocation && (
        <>
          <li className="d-lg-block d-none mt-2">
            <VirtualLocation {...slot.virtualLocation} className="ms-1 text-secondary" />
          </li>
          <li className="d-lg-none mt-2">
            <img src={teamsIcon} width="24px" height="24px" alt="teams" onClick={() => openModal()} />
          </li>
        </>
      )}
      {showModal && <VirtualLocationInfoModal virtualLocation={slot.virtualLocation} onClose={closeModal} />}
    </>
  );
};
