import * as React from "react";
import * as moment from "moment";
import { INVITATION_STATUS, INVITATION_STATUS_LIST } from "api/events/event.types";
import { Link } from "react-router-dom";
import { ROUTES } from "common/routes";
import * as styles from "components/Event/Dashboard/EventCard/EventCard.scss";
import { EventItem } from "common/event/event.types";
import { isDateBeforeToday } from "utils/dateUtils";
import { get, isEmpty } from "lodash";
import { logMessage } from "utils/logger/logger";
import { features } from "utils/logger/features";
import { LogTypes } from "utils/logger/logger.typings";
import { useGetEventDetailQuery } from "queries/useGetEventDetailQuery";
import { SlotIssuersTimeline } from "components/Event/Corporate/EventGroups/SlotIssuersTimeline";
import { classNames } from "@ic-anywhere/ic-utils";

const DD_MMM = " DD MMM";

type Props = {
  event: EventItem;
  displayMoreInfo: boolean;
};

export const EventGroupItem: React.FC<Props> = ({ event }) => {
  const eventCity = get(event, "venue.address.city", "");
  const { data: eventDetail, isFetching } = useGetEventDetailQuery(event.id);

  if (isFetching) {
    return null;
  }

  const { issuers, slots } = eventDetail;

  return (
    <div
      className={classNames(
        "card card-bordered pb-md-0 p-3 mb-2 mb-md-3 p-md-4",
        isDateBeforeToday(event.endDate) ? styles["past-event"] : undefined
      )}
    >
      <div>
        <div className="row">
          {event.invitationStatus !== undefined && (
            <>
              <span
                className={`d-none d-md-block d-lg-block badge badge-flat-${getInvitationColor(
                  event
                )} badge-prepend-square ms-2`}
              >
                {`${INVITATION_STATUS_LIST[event.invitationStatus]}`}
              </span>
              <span
                className={`d-sm-block d-md-none badge badge-discreet-${getInvitationColor(
                  event
                )} badge-prepend-square ms-2`}
              >
                {`${INVITATION_STATUS_LIST[event.invitationStatus]}`}
              </span>
            </>
          )}
        </div>
        <div title={event.name}>
          <h3 className="fw-bolder">
            <span className="d-md-inline d-lg-inline">
              {moment(event.startDate).format(DD_MMM)}
              {moment(event.startDate).format(DD_MMM) !== moment(event.endDate).format(DD_MMM) && (
                <>&nbsp;-&nbsp;{moment(event.endDate).format(DD_MMM)}</>
              )}
              {!isEmpty(eventCity) && <>&nbsp;:&nbsp;</>}
            </span>
            <span className="primary">{!isEmpty(eventCity) && eventCity}</span>
          </h3>
        </div>
      </div>
      <SlotIssuersTimeline slots={slots} issuers={issuers} />
      <div className="d-flex justify-content-end">
        <Link
          to={`${ROUTES.EVENT_DETAIL_CORPORATE.replace(":id", event.id)}`}
          className="btn btn-discreet-primary"
          onClick={logRoadshowGroupPageMoreInfoNavigate}
        >
          More Info
        </Link>
      </div>
    </div>
  );
};

const getInvitationColor = (event) => {
  let invitationLabelColor;
  switch (INVITATION_STATUS_LIST[event.invitationStatus]) {
    case INVITATION_STATUS.ATTENDING:
      invitationLabelColor = "puerto-rico";
      break;
    case INVITATION_STATUS.PENDING:
      invitationLabelColor = "apricot";
      break;
    case INVITATION_STATUS.DECLINED_NOT_INTERESTED:
    case INVITATION_STATUS.DECLINED_NOT_AVAILABLE:
      invitationLabelColor = "valencia";
      break;
    default:
      return "primary-alt";
  }

  return invitationLabelColor;
};

const logRoadshowGroupPageMoreInfoNavigate = () => {
  logMessage({
    ...features.roadshowGroupPageMoreInfoNavigate,
    type: LogTypes.functional,
    feature: "event",
    name: "Roadshow group page more info navigate",
  });
};
