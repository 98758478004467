import * as React from "react";
import { EVENT_CATEGORY, TagFilter } from "containers/event/event.types";
import { filter, map } from "lodash";

type TagGroupProps = {
  title: string;
  onFilter: (filterId: EVENT_CATEGORY) => void;
  tagFilters: TagFilter[];
};

const TagGroupToRename: React.FC<TagGroupProps> = ({ title, tagFilters, onFilter }) => {
  return (
    <div>
      <div className="text-secondary mb-1">{title}</div>
      {map(
        filter(tagFilters, (tf) => tf.id !== EVENT_CATEGORY.CLEAR),
        (tagFilter) => {
          return (
            <button
              key={tagFilter.id}
              id={tagFilter.id.toString()}
              className={
                tagFilter.enabled
                  ? `btn border border-${tagFilter.color} btn-${tagFilter.color} mb-1 me-1 active text-white`
                  : `btn border border-${tagFilter.color} btn-outline-${tagFilter.color} mb-1 me-1`
              }
              onClick={(e) => {
                onFilter(tagFilter.id);
                e.currentTarget.blur();
              }}
              data-category={tagFilter.category}
            >
              {tagFilter.name}
            </button>
          );
        }
      )}
    </div>
  );
};

export default TagGroupToRename;
