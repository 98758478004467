import * as React from "react";
import { head, last, once, partition, sortBy, uniqBy } from "lodash";
import { EventBundleRoadshow } from "containers/event/event.types";
import * as moment from "moment";
import Header from "components/layout/Header/PageHeader/Header";
import { isDateBeforeToday } from "utils/dateUtils";
import { logMessage } from "utils/logger/logger";
import { features } from "utils/logger/features";
import { LogTypes } from "utils/logger/logger.typings";
import Responsive from "components/common/Responsive/Responsive";
import { useParams } from "react-router";
import { useGetEventsOfGroup } from "queries/useGetMyEventsQuery";
import { EventItem } from "common/event/event.types";
import { EventGroupItem } from "components/Event/Corporate/EventGroups/EventGroupItem";
import { formatTitleDate } from "@ic-anywhere/ic-utils";
import { Loader } from "@sgbs-ui/core";

export interface DispatchProps {
  loadEventDetailsByGroupName: (groupName) => void;
}

export interface StateProps {
  eventBundleRoadshows: EventBundleRoadshow[];
  user: { email: string; userTeamId: string };
  error: string;
  isFetching: boolean;
  params?: any;
}

export type Props = StateProps & DispatchProps;

const EventGroupView: React.FC = () => {
  const logPagePerformanceOnce = once(logLoadingAction);

  const { groupName } = useParams<{ groupName: string }>();
  const { data: events, isFetching } = useGetEventsOfGroup(groupName);

  React.useEffect(() => {
    if (!isFetching) {
      logPagePerformanceOnce();
    }
  }, [events, isFetching]);

  const eventHeaderFirstDate = head(events)?.startDate;
  const eventHeaderLastDate = last(events)?.endDate;
  const orderedEvents = getOrderedEvents(events);

  return isFetching ? (
    <Loader isVisible={true} />
  ) : (
    <>
      <Header mainTitle={groupName} />
      <div className="container">
        <div className="col-md-12 mb-4">
          <Responsive
            mobileChildren={
              <h2 className="primary pt-4">
                <span>{formatTitleDate(eventHeaderFirstDate, eventHeaderLastDate)}</span>
              </h2>
            }
            children={
              <h1 className="pt-5">
                <span className="fw-bolder">{formatTitleDate(eventHeaderFirstDate, eventHeaderLastDate)}</span>
              </h1>
            }
          />
        </div>
        <div className="col-md-8">
          {orderedEvents.map((event) => (
            <EventGroupItem key={event.id} event={event} displayMoreInfo={true} />
          ))}
        </div>
      </div>
    </>
  );
};
export default EventGroupView;

const logLoadingAction = () => {
  logMessage({
    ...features.roadshowGroupPageLoading,
    type: LogTypes.functional,
    feature: "event",
    name: "Roadshow group page loading",
  });
};

const getOrderedEvents = (events: EventItem[]): EventItem[] => {
  const [nearest, past] = partition(events, (event) => !isDateBeforeToday(event.endDate));
  const nearestSorted = sortBy(nearest, (event) => moment(event.startDate).valueOf());
  const pastSorted = sortBy(past, (event) => -moment(event.startDate).valueOf());
  return uniqBy([...nearestSorted, ...pastSorted], (event) => event.id);
};
