import * as appLogo from "assets/images/logo_sgm_events_72dpi.png";
import * as sgLogo from "assets/images/socgen_logo_full.svg";
import * as sgLogoSm from "assets/images/sg-small.png";
import * as React from "react";
import { SgHeader } from "components/layout/Header/SgHeader";
import { ROUTES } from "common/routes";
import { Link } from "react-router-dom";
import Responsive from "components/common/Responsive/Responsive";

const Header: React.FunctionComponent = () => (
  <header>
    <nav className="p-4 navbar navbar-expand-xl navbar-light bg-white">
      <div className="d-flex">
        <Link to={ROUTES.EVENTS_DASHBOARD} className="navbar-brand d-flex align-items-center">
          <Responsive
            mobileChildren={<img src={sgLogoSm} alt="Logo SG" height="32" />}
            children={<img src={sgLogo} alt="Logo SG" height="32" />}
          />
        </Link>
      </div>
      <div className="d-flex align-items-center order-xl-1">
        <SgHeader currentLanguage={"en"} />
        <a className="d-none d-md-block overflow-visible ms-3">
          <img src={appLogo} alt="Application Logo" />
        </a>
      </div>
      <div className="navbar-collapse justify-content-start collapse" id="main-navbar" />
    </nav>
  </header>
);

export default Header;
