import * as React from "react";
import { isEmpty } from "lodash";
interface Props {
  title: string | React.ReactNode;
  id: number;
  accordionId: string;
  skipFirst?: boolean;
  onCollapse?: (expanded: boolean) => void;
  bottomText?: string | React.ReactNode;
}

export const CollapsibleContactsCard: React.FC<Props> = ({
  id,
  title,
  accordionId,
  bottomText,
  children,
  onCollapse,
}) => {
  const buttonRef = React.useRef<HTMLButtonElement>();
  const buttonClassName = "btn btn-flat-primary justify-content-start ps-0 ms-2 mw-100 text-truncate collapsed";

  const handleFeatureTrackingOnClick = () => {
    if (buttonRef && buttonRef.current) {
      const expanded = !JSON.parse(buttonRef.current.getAttribute("aria-expanded"));
      onCollapse?.(expanded);
    }
  };

  const handleCollapseContent = () => {
    const content = document.getElementById(`collapseOne${id}`);
    if (buttonRef && buttonRef.current) {
      buttonRef.current.className.includes("collapsed")
        ? buttonRef.current.setAttribute("class", buttonClassName.replace("collapsed", ""))
        : buttonRef.current.setAttribute("class", buttonClassName);
    }
    content.className !== "collapse"
      ? content.setAttribute("class", "collapse")
      : content.setAttribute("class", "collapse show");
  };

  return (
    <div className="card mb-2 border-top p-md-3 p-1">
      <div className="ps-0 card-header" id="headingOne">
        <button
          id={`dataItem${id}`}
          className={buttonClassName}
          data-toggle="collapse"
          data-target={`#collapseOne${id}`}
          aria-expanded="false"
          aria-controls={"collapseTwo"}
          ref={buttonRef}
          onClick={() => {
            handleFeatureTrackingOnClick();
            handleCollapseContent();
          }}
        >
          <i className="icon icon-sm ms-0 me-2 d-inline-block">keyboard_arrow_right</i>
          <span className="text-primary fs-4 fw-bolder d-inline-block">{title}</span>
        </button>
      </div>
      <div id={`collapseOne${id}`} className="collapse" aria-labelledby="headingOne" data-parent={`#${accordionId}`}>
        <div className="card-body pt-0 ps-xs-0">{children}</div>
        {!isEmpty(bottomText) && <>{bottomText}</>}
      </div>
    </div>
  );
};
