import * as React from "react";
import { isEmpty, some } from "lodash";
import { BookingDetail, Event } from "api/events/event.types";
import { CardUsefulContacts } from "components/EventInvitation/CardItem/CardUsefulContacts";
import { CardSpeakers } from "components/EventInvitation/CardItem/CardSpeakers";
import {
  BookingEditModel,
  EditableSlotItemBody,
} from "components/EventInvitation/EditableSlotItem/EditableSlotItemBody";
import { ReadBookingDetailsItem } from "components/EventInvitation/EditableSlotItem/ReadBookingDetailsItem";
import { CardMap } from "components/EventInvitation/CardItem/CardMap";
import { Loader } from "@sgbs-ui/core";
import { Card, HtmlSanitizer } from "@ic-anywhere/ic-components";

type EventInvitationContentProps = {
  event: Event;
  isLoading: boolean;
  isUpdating: boolean;
  bookingDetails: BookingDetail[];
  programView: "read" | "edit" | "hidden";
  onSave: (bookingsToModify: BookingEditModel[], allDeclined: boolean) => void;
  onCancel: () => void;
};

export const EventInvitationContent: React.VFC<EventInvitationContentProps> = ({
  event,
  bookingDetails,
  isLoading,
  programView,
  isUpdating,
  onSave,
  onCancel,
}) => {
  const abstractCard: JSX.Element = (
    <>
      {event.description && (
        <Card title="Overview">
          <HtmlSanitizer content={event.description} />
        </Card>
      )}
    </>
  );
  const eventDateRange = { startDate: event.startDate, endDate: event.endDate };
  const isHideProgram = programView === "hidden";
  const isLightRoadshow = event.category === "LightRoadshow";
  const isBookingEmptyForUser = some(bookingDetails, ({ booking }) => !!booking);
  const showCardProgram =
    !isEmpty(bookingDetails) && ((programView === "read" && isBookingEmptyForUser) || programView === "edit");
  return (
    <div className="row">
      <div className="col-md-8">
        <div className="d-none d-lg-block mt-4">{abstractCard}</div>
        {isLoading ? (
          <Loader isVisible={true} />
        ) : (
          showCardProgram && (
            <div className={`mt-4 mt-lg-0 ${isHideProgram ? "d-none d-md-block" : ""}`}>
              <Card>
                <div className="row">
                  <div className="col-8 align-items-center">
                    {isHideProgram && <h4 className="card-title mb-4">Invitation declined</h4>}
                  </div>
                </div>
                {!isHideProgram && (
                  <div className="row">
                    <div className="px-lg-2">
                      {programView === "edit" ? (
                        <EditableSlotItemBody
                          eventDateRange={eventDateRange}
                          bookingDetails={bookingDetails}
                          isUpdating={isUpdating}
                          onSave={onSave}
                          onCancel={onCancel}
                        />
                      ) : (
                        <ReadBookingDetailsItem
                          eventDateRange={eventDateRange}
                          bookingDetails={bookingDetails}
                          showSlotStatus={true}
                        />
                      )}
                    </div>
                  </div>
                )}
              </Card>
            </div>
          )
        )}
        <div className={`mt-4 d-lg-none ${isHideProgram && "mt-4"}`}>{abstractCard}</div>
        {event.usefulInformation && (
          <Card title={"Useful information"}>
            <div dangerouslySetInnerHTML={{ __html: event.usefulInformation }} />
          </Card>
        )}
      </div>
      <div className="col-md-4 mt-md-4">
        <CardUsefulContacts
          eventManagers={event.eventManagerContacts}
          internalSpeakers={event.internalSpeakerContacts}
          businessContacts={event.businessContacts}
          classNames="card-bordered"
        />
        <CardSpeakers
          title="Speakers"
          speakers={[...event.externalSpeakerContacts, ...event.internalSpeakerContacts]}
        />

        {!isLightRoadshow && (
          <CardMap
            eventAddress={{
              ...event.venue.address,
              venueName: event.venue.name, // WARN: venue name come from event
            }}
            bookingDetails={bookingDetails}
          />
        )}
      </div>
    </div>
  );
};
