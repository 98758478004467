import * as React from "react";
import { filter, map, uniq, uniqBy } from "lodash";
import { EventContact } from "api/events/event.types";
import ContactItem from "./CardItem/ContactItem";

export interface ContactGroup {
  accountName: string;
  externalSpeakerContacts: EventContact[];
}

export const getContactsGroupByAccountName = (contacts: EventContact[]): ContactGroup[] => {
  const uniqueContacts = contacts ? uniqBy(contacts, "id") : [];

  return map(uniq(map(uniqueContacts, ({ accountName }) => accountName)), (account) => ({
    accountName: account,
    externalSpeakerContacts: filter(uniqueContacts, (contact) => contact.accountName === account),
  }));
};

type ContactsGroupsProps = {
  contacts: EventContact[];
  skipFirst?: boolean;
  contactInfoModal?: boolean;
};

export const ContactsGroups: React.VFC<ContactsGroupsProps> = ({ contacts, skipFirst, contactInfoModal }) => {
  const contactsGroupByAccountName = getContactsGroupByAccountName(contacts);
  return (
    <div>
      {map(contactsGroupByAccountName, ({ accountName, externalSpeakerContacts }, index: number) => (
        <div key={accountName}>
          {skipFirst && index === 0 ? null : (
            <div className={index === 0 ? "ps-2" : "ps-2 pt-4 border-top border-white"}>
              <span className="text-primary fs-4 fw-bolder">{accountName}</span>
            </div>
          )}
          {map(externalSpeakerContacts, (contact) => (
            <ContactItem key={contact.id} {...contact} contactInfoModal={contactInfoModal} />
          ))}
        </div>
      ))}
    </div>
  );
};
